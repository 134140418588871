import React from "react";

import Legenda from "./Leyenda";
import AcademicCondition from "../AcademicCondition";

import "../secundariacss.css";

import Attendance from "../Attendance";
import Signatures from "../Signatures";
import Header from "../Header";
const SecundariaBulletin = ({ data,generatePDF }) => {
  return (
    <>
      <hr className="d-print-none"></hr>

      <div className="table-responsive">
        <Header data={data} generatePDF={generatePDF} />

        {data?.status_id === "1" ? ( // remove the role check here
          <>
            <table
              className="beta bulletin"
              width="100%"
              border={0}
              cellSpacing={0}
              cellPadding={0}
            >
              <tbody>
                <tr>
                  <td
                    className="text-center font-weight-bold"
                    colSpan={2 * data.p_1.length + 1}
                  >
                    Año Escolar 2024 - 2025
                  </td>
                </tr>
                <tr>
                  <td className="p-2" />
                  {data?.p_1 &&
                    data.p_1.map((item, index) => {
                      return (
                        <td
                          key={index + "subject_juntas_p1"}
                          colSpan={2}
                          className="text-center font-weight-bold"
                        >
                          {item.subject_name}
                        </td>
                      );
                    })}
                </tr>
                <tr>
                  <td className="p-2" />
                  {data?.p_1 &&
                    data.p_1.map((item, index) => {
                      return (
                        <React.Fragment key={"acad_conduct_n1" + index}>
                          <td className="text-center">Acad.</td>
                          <td className="text-center">Cond.</td>
                        </React.Fragment>
                      );
                    })}
                </tr>
                <tr>
                  <td className="text-center">1</td>
                  {data?.p_1 &&
                    data.p_1.map((item, index) => {
                      return (
                        <React.Fragment key={"grade_conduct_p1" + index}>
                          <td className="text-center">
                            {item.final_grade < 70 ? (
                              <u className="font-weight-bold ">
                                {item.final_grade}
                              </u>
                            ) : (
                              item.final_grade
                            )}
                          </td>
                          <td className="text-center">
                            {item.conduct < 70 ? (
                              <u className="font-weight-bold ">
                                {item.conduct}
                              </u>
                            ) : (
                              item.conduct
                            )}
                          </td>
                        </React.Fragment>
                      );
                    })}
                </tr>
                {/* Pedagogical recovery */}
                <tr>
                  <td className="text-center">RP</td>
                  {data?.p_1
                    ? data.p_1.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p2" + index}>
                            <td className="text-center">{item.rp1}</td>
                            <td className="text-center"></td>
                          </React.Fragment>
                        );
                      })
                    : data.p_1.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p2" + index}>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                          </React.Fragment>
                        );
                      })}
                </tr>
                {/* 2nd period */}
                <tr>
                  <td className="text-center">2</td>
                  {data?.p_2
                    ? data.p_2.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p2" + index}>
                            <td className="text-center">
                              {item.final_grade < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.final_grade}
                                </u>
                              ) : (
                                item.final_grade
                              )}
                            </td>
                            <td className="text-center">
                              {item.conduct < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.conduct}
                                </u>
                              ) : (
                                item.conduct
                              )}
                            </td>
                          </React.Fragment>
                        );
                      })
                    : data.p_1.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p2" + index}>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                          </React.Fragment>
                        );
                      })}
                </tr>

                <tr>
                  <td className="text-center">RP</td>
                  {data?.p_2
                    ? data.p_2.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p2" + index}>
                            <td className="text-center">{item.rp2}</td>
                            <td className="text-center"></td>
                          </React.Fragment>
                        );
                      })
                    : data.p_1.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p2" + index}>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                          </React.Fragment>
                        );
                      })}
                </tr>

                <tr>
                  <td className="text-center">3</td>
                  {data?.p_3
                    ? data.p_3.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p3" + index}>
                            <td className="text-center">
                              {item.final_grade < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.final_grade}
                                </u>
                              ) : (
                                item.final_grade
                              )}
                            </td>
                            <td className="text-center">
                              {item.conduct < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.conduct}
                                </u>
                              ) : (
                                item.conduct
                              )}
                            </td>
                          </React.Fragment>
                        );
                      })
                    : data.p_1.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p3" + index}>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                          </React.Fragment>
                        );
                      })}
                </tr>
                <tr>
                  <td className="text-center">RP</td>
                  {data?.rp3
                    ? data.rp3.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p2" + index}>
                            <td className="text-center">
                              {item.final_grade < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.final_grade}
                                </u>
                              ) : (
                                item.final_grade
                              )}
                            </td>
                            <td className="text-center">
                              {item.conduct < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.conduct}
                                </u>
                              ) : (
                                item.conduct
                              )}
                            </td>
                          </React.Fragment>
                        );
                      })
                    : data.p_1.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p2" + index}>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                          </React.Fragment>
                        );
                      })}
                </tr>
                <tr>
                  <td className="text-center">4</td>
                  {data?.p_4
                    ? data.p_4.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p4" + index}>
                            <td className="text-center">
                              {item.final_grade < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.final_grade}
                                </u>
                              ) : (
                                item.final_grade
                              )}
                            </td>
                            <td className="text-center">
                              {item.conduct < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.conduct}
                                </u>
                              ) : (
                                item.conduct
                              )}
                            </td>
                          </React.Fragment>
                        );
                      })
                    : data.p_1.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p4" + index}>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                          </React.Fragment>
                        );
                      })}
                </tr>
                <tr>
                  <td className="text-center">RP</td>
                  {data?.rp4
                    ? data.rp4.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p2" + index}>
                            <td className="text-center">
                              {item.final_grade < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.final_grade}
                                </u>
                              ) : (
                                item.final_grade
                              )}
                            </td>
                            <td className="text-center">
                              {item.conduct < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.conduct}
                                </u>
                              ) : (
                                item.conduct
                              )}
                            </td>
                          </React.Fragment>
                        );
                      })
                    : data.p_1.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p2" + index}>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                          </React.Fragment>
                        );
                      })}
                </tr>

                <tr>
                  <td
                    className="pt-4 pl-2 font-weight-bold"
                    colSpan={2 * data?.p_1?.length + 1}
                  >
                    PROMEDIO:
                  </td>
                </tr>
                <tr>
                  <td className="pl-2">COMPLE. </td>
                  {data?.p_4
                    ? data?.PROFIN?.map((item, index) => {
                        return (
                          <React.Fragment key={"PROFIN" + index}>
                            <td className="text-center">
                              {item.final_grade < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.final_grade}
                                </u>
                              ) : (
                                item.final_grade
                              )}
                            </td>
                            <td className="text-center">
                              {item.conduct < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.conduct}
                                </u>
                              ) : (
                                item.conduct
                              )}
                            </td>
                          </React.Fragment>
                        );
                      })
                    : data.p_1.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p4" + index}>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                          </React.Fragment>
                        );
                      })}
                </tr>
                <tr>
                  <td className="pl-2">PROM. FIN. </td>{" "}
                  {/*Dinamically change to Recuperacion if is level primeria */}
                  {data?.p_1
                    ? data.ProFinal.map((item, index) => {
                        return (
                          <React.Fragment key={"PROFIN_comple" + index}>
                            <td className="text-center">
                              {item.average_final_grade_f < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.average_final_grade_f}
                                </u>
                              ) : (
                                item.average_final_grade_f
                              )}
                            </td>
                            <td className="text-center">
                              {item.average_conduct < 70 ? (
                                <u className="font-weight-bold ">
                                  {item.average_conduct}
                                </u>
                              ) : (
                                item.average_conduct
                              )}
                            </td>
                          </React.Fragment>
                        );
                      })
                    : data.p_1.map((item, index) => {
                        return (
                          <React.Fragment key={"grade_conduct_p4" + index}>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                          </React.Fragment>
                        );
                      })}
                </tr>
              </tbody>
            </table>

            <AcademicCondition condition={data?.condition} />
            <Attendance attendance={data?.attendance} />
            <Legenda />
            <Signatures data={data} />
          </>
        ) : (
          <div>
            <div className="text-center">
              <span style={{ fontSize: "3em", color: "Tomato" }}>
                <i className="fas fa-exclamation-triangle fa-3x" />
              </span>
            </div>

            <h2 className="text-center font-weight-bold">
              Por favor comunícate con la Administración
            </h2>
          </div>
        )}
      </div>
      {/* <div className="page-break"></div> */}
    </>
  );
};
export default SecundariaBulletin;
